<template>
    <div class="home">
        <h1> Admin Dashboard</h1>

        <router-link to="/admin/createCard">Create Card</router-link> |
        <router-link to="/admin/getAllCards">Get All Cards</router-link>
    </div>
</template>

<script>
export default {
  name: 'HomeView'
}
</script>
