<template>
  <div class="home">
    <router-link to="/admin">Admin View</router-link> |
    <router-link to="/question">Study View</router-link>

    <p> 
      The application is running on free tiers of Render, Vercel, ElephantSQL. The first query you perform will take 2 minutes to complete. <br/>
      This is because Render and ElephantSQL shuts down server with inactivity. After that each query should take 1-5 seconds.
    </p>
  </div>
</template>

<script>

export default {
  name: 'HomeView'
}
</script>
