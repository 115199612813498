<template>
    <div class="input-box">

        <h4> {{ label }}</h4>
        <input type="text" v-model="inputValue">

    </div>
  
</template>
  
<script>
export default {
    name: 'InputBox',
    props: {
        label: String,
        modelValue: String
    },
    computed:{
        inputValue:{
            get(){
                return this.modelValue;
            },
            set(value){
                this.$emit('update:modelValue', value);
            }
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}</style>
  