<template>
  <div class="all-cards">
    <h1> All cards </h1>

    <table>
      <tr>
        <th> Id </th>
        <th>Word</th>
        <th>Definition</th>
        <th>Current Bin</th>
        <th> Time Of Next Appearance</th>
        <th> Times Incorrectly Picked </th>
      </tr>

      <tr v-for="card in cards" :key="card.id">
        <td>{{ card.id }}</td>
        <td>{{ card.word }}</td>
        <td>{{ card.definition }}</td>
        <td>{{ card.currentbin }}</td>
        <td>{{ new Date(card.timeofnextappearance * 1000) }}</td>
        <td>{{ card.timeincorrectlypicked }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'GetAllCardsView',
  data() {
    return {
      cards: []
    };
  },
  mounted() {
    this.getAllCards();
  },
  methods: {
    getAllCards() {
      axios.get(process.env.VUE_APP_GET_ALL_CARDS_URL + "?userid=1")
        .then((response) => {

          this.cards = response.data;

        })
        .catch((error) => {

          console.error(error);
        });
    }
  }
}
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
