<template>
  <div class="question">
    <h1> Answer </h1>
    <h4> {{ $route.query.word }} </h4>

    <h4> {{ $route.query.definition }} </h4>

    <button @click="updateBin(true)">I got it</button>
    <br />
    <button @click="updateBin(false)">I did not got it</button>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  name: 'AnswerView',
  data() {
    return {
      word: null,
      definition: null
    };
  },
  created() {
    this.word = this.$route.query.word;
    this.definition = this.$route.query.definition;
  },
  methods: {
    updateBin(isCorrectAnswer) {
      const formData = {
        word: this.word,
        isCorrectAnswer: isCorrectAnswer
      }

      // TODO: Async Run this function while redirecting to next question. 
      axios.post(process.env.VUE_APP_UPDATE_BIN_URL, formData)
        .then((response) => {

          window.location.href = '/question';
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>
  