<template>
  <div class="question">
    <h1> Question </h1>
    <h4> {{ word }} </h4>

    <router-link :to="{ name: 'answer', query: {word: word, definition: definition }}">Show Result</router-link>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  name: 'QuestionView',
  data() {
    return {
      word: 'Please Wait...',
      definition: ''
    };
  },
  mounted() {
    this.getValidQuestion();
  },
  methods: {
    getValidQuestion() {
      axios.get(process.env.VUE_APP_GET_VALID_CARD_URL + "?userid=1")
        .then((response) => {
          
          if(response.data.message){
            this.word = response.data.message;
          } else {
            this.word = response.data.word;
            this.definition = response.data.definition;
          }
        })
        .catch((error) => {
          
          console.error(error);
        });
    }
  }
}
</script>
  