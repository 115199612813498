<template>
    <div class="home">
        <h1> Create Card View </h1>

        <InputBox type="text" v-model="word" label="Word" />
        <InputBox type="text" v-model="definition" label="Definition" />
        <input type="submit" value="Submit" @click="submitButton"/>

    </div>
</template>

<script>
import axios from 'axios';
import InputBox from '@/components/InputBox.vue'

export default {
  name: 'CreateCardView',
  data() {
    return {
      word: '',
      definition: '',
    };
  },
  components: {
    InputBox
  },
  methods: {
    submitButton() {
      const formData = {
        word: this.word,
        definition: this.definition,
      }

      console.log(formData);

      // TODO: Make this call async.
      axios.post(process.env.VUE_APP_CREATE_CARD_URL, formData)
        .then((response) => {
    
          this.word = '';
          this.definition = '';

          window.location.href = '/admin';
        })
        .catch((error) => {
        
          console.error(error);
      });
    }
  }
}
</script>
